import { FC } from 'react';

export interface WardrobeCardProps {
  name: string;
}

const WardrobeCard: FC<WardrobeCardProps> = ({ name }) => {
  return (
    <div
      className="flex-1 bg-slate-400 rounded-lg h-fit"
      style={{ minWidth: '100px', maxWidth: '50vw' }}
    >
      <p className="text-sm" style={{ height: `${name.length}rem` }}>
        {name}
      </p>
    </div>
  );
};

export default WardrobeCard;
