import { FC } from 'react';
import { WardrobeCard } from './components';

const Wardrobe: FC = () => {
  const cardsData = new Array(20).fill(1).map((_, idx) => ({
    title: '',
    card: <WardrobeCard key={idx} name={`Card ${idx + 1}`} />,
  }));
  return (
    <div className="flex-1 flex-wrap gap-2 flex flex-row p-4">
      {cardsData.map(({ card }) => card)}
    </div>
  );
};

export default Wardrobe;
