import { useEffect, useRef } from 'react';

const useBeforeInstall = () => {
  const isInstalled = useRef(false);
  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (e) => {
      console.log(e);
    });
  }, []);
  return {
    isInstalled: isInstalled.current,
  };
};

export default useBeforeInstall;
