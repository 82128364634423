import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export enum PageName {
  Wardrobe = 'wardrobe',
  Settings = 'settings',
  Outfit = 'outfit',
}

export interface Page {
  id: PageName;
  path: `/app/${string}`;
  title?: string;
}

const usePages = () => {
  const { t, i18n } = useTranslation();
  const pages: Page[] = useMemo(() => {
    return [
      {
        id: PageName.Wardrobe,
        path: `/app/${PageName.Wardrobe}`,
        title: t('wardrobe'),
      },
      {
        id: PageName.Outfit,
        path: `/app/${PageName.Outfit}`,
        title: t('outfit'),
      },
      {
        id: PageName.Settings,
        path: `/app/${PageName.Settings}`,
        title: t('settings'),
      },
    ];
  }, [i18n.language]);

  const currentPath = location.pathname;
  const currentPage = pages.find(p => p.path === currentPath);

  return { allPages: pages, currentPage };
};

export default usePages;
