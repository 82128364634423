import { BrowserRouter, Route, Routes, redirect, useNavigate } from 'react-router-dom';
import App from './App';
import MainLayout from '@/layouts/main';
import { useEffect } from 'react';
import Wardrobe from '@/pages/wardrobe';
import Settings from '@/pages/settings';
import Outfit from '@/pages/outfit';

const NoMatch = () => {
  const history = useNavigate();
  useEffect(() => history('/'), []);

  return null;
};

const Router = () => {
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="app" element={<MainLayout />} ErrorBoundary={App}>
          <Route path="wardrobe" element={<Wardrobe />} />
          <Route path="settings" element={<Settings />} />
          <Route path="outfit" element={<Outfit />} />
        </Route>
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
