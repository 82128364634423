import i18next from 'i18next';
import localeDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import en from './translations/en.json';
import zh_CN from './translations/zh-CN.json';

i18next
  .use(initReactI18next)
  .use(localeDetector)
  .init({
    debug: true,
    resources: {
      en: {
        translation: en,
      },
      'zh-CN': {
        translation: zh_CN,
      },
    },
    defaultNS: 'translation',
    fallbackLng: 'zh-CN',
    supportedLngs: ['en', 'zh-CN'],
  });
