import { Cell } from '@arco-design/mobile-react';
import { FC } from 'react';
import LanguageSettingsItem from './items/general/language';

const Settings: FC = () => {
  return (
    <div className="flex flex-col w-full my-4">
      <Cell.Group className="px-4">
        <LanguageSettingsItem />
      </Cell.Group>
    </div>
  );
};

export default Settings;
