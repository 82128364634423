import { useCallback, useEffect, useRef } from 'react';

const useStatusBarColor = () => {
  const $metaTag = useRef<HTMLMetaElement>();
  const setColor = useCallback(
    (color = '#F9FAFB') => {
      if ($metaTag.current?.content) {
        $metaTag.current.content = color;
      }
    },
    [$metaTag.current]
  );

  useEffect(() => {
    const $themeColor = document.querySelector("meta[name='theme-color']");
    if ($themeColor) {
      $metaTag.current = $themeColor as HTMLMetaElement;
    }
  }, []);

  return {
    setColor,
  };
};

export default useStatusBarColor;
