import MainNavBar from '@/components/main-nav-bar';
import MainTabBar from '@/components/main-tab-bar';
import { FC, PropsWithChildren, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

export interface MainLayoutProps {}

const MainLayout: FC<PropsWithChildren<MainLayoutProps>> = () => {
  return (
    <section id="main-layout" className="flex flex-col w-full h-full bg-gray-50">
      <MainNavBar />
      <div id="container" className="flex-1 w-full overflow-y-auto scroll-smooth">
        <Outlet />
      </div>
      <MainTabBar />
    </section>
  );
};

export default MainLayout;
