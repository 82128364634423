import { ActionSheet, Cell } from '@arco-design/mobile-react';
import { IconLanguage } from '@arco-design/web-react/icon';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSettingsItem = () => {
  const { t, i18n } = useTranslation();

  const langs: Record<string, string> = useMemo(() => {
    return {
      'zh-CN': '简体中文',
      en: 'English',
    };
  }, [i18n.languages, i18n.language]);
  const onClick = useCallback(() => {
    window.modalInstance = ActionSheet.open({
      items: Object.keys(langs)
        .map(lang => {
          return {
            content: langs[lang],
            onClick: () => {
              i18n.changeLanguage(lang);
            },
          };
        })
        .filter(l => l.content),
      className: 'pb-safe',
    });
  }, [i18n.languages, i18n.language]);

  return (
    <Cell
      label={t('settings.language')}
      icon={<IconLanguage className="h-5 w-5" />}
      showArrow
      onClick={onClick}
    />
  );
};

export default LanguageSettingsItem;
