import { IconSetting, IconShop } from '@arco-design/mobile-react/esm/icon';
import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import usePages, { Page, PageName } from './use-pages';
import { IconSkin } from '@arco-design/web-react/icon';

interface Tab {
  id: PageName;
  icon: ReactNode;
  page: Page;
}

const useMainTab = () => {
  const { allPages } = usePages();
  const { t, i18n } = useTranslation();
  const tabs = useMemo(() => {
    return [
      {
        id: PageName.Wardrobe,
        icon: <IconShop />,
        page: allPages.find(p => p.id === PageName.Wardrobe),
      },
      {
        id: PageName.Outfit,
        icon: <IconSkin className="h-5 w-5" />,
        page: allPages.find(p => p.id === PageName.Outfit),
      },
      {
        id: PageName.Settings,
        icon: <IconSetting />,
        page: allPages.find(p => p.id === PageName.Settings),
      },
    ] as Tab[];
  }, [i18n.language]);

  return {
    tabs,
  };
};

export default useMainTab;
