import useBeforeInstall from '@/hooks/pwa/use-before-install';
import useStatusBarColor from '@/hooks/pwa/use-status-bar-color';
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './App.css';
import logo from './logo.png';

function App() {
  useBeforeInstall();

  const history = useNavigate();
  const { setColor } = useStatusBarColor();
  const $App = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setColor('#155e75');
    const timer = setInterval(() => {
      if (!$App.current) {
        return;
      }
      const color = window.getComputedStyle($App.current).backgroundColor;
      setColor(color);
    }, 100);
    return () => {
      setColor();
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      history('/app/wardrobe');
    }, 3000);
  }, []);

  return (
    <div className="App">
      <header ref={$App} className="App-header">
        <img
          src={logo}
          className={`h-[200px] w-[200px] rounded-full animate-bounce shadow-2xl`}
          alt="logo"
        />
      </header>
    </div>
  );
}

export default App;
