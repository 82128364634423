import { FC } from 'react';

const Outfit: FC = () => {
  return (
    <div className="flex flex-col w-full p-4">
      <h1>OOTD</h1>
    </div>
  );
};

export default Outfit;
