import useMainTab from '@/hooks/biz/navigation/use-main-tab';
import { TabBar } from '@arco-design/mobile-react';
import { useLocation, useNavigate } from 'react-router-dom';

const MainTabBar = () => {
  const { tabs } = useMainTab();
  const history = useNavigate();
  const location = useLocation();

  return (
    <TabBar
      className="bg-white shadow-lg pb-safe"
      fixed={false}
      onChange={idx => {
        const tab = tabs[idx];
        history(tab.page.path);
      }}
      defaultActiveIndex={tabs.findIndex(t => t.page.path === location.pathname)}
    >
      {tabs.map(tab => (
        <TabBar.Item title={tab.page.title} icon={tab.icon} key={tab.id} />
      ))}
    </TabBar>
  );
};

export default MainTabBar;
