import usePages from '@/hooks/biz/navigation/use-pages';
import { NavBar } from '@arco-design/mobile-react';
import React from 'react';
import { useLocation } from 'react-router-dom';

const MainNavBar = () => {
  const { allPages } = usePages();
  const location = useLocation();

  return (
    <NavBar
      leftContent={<React.Fragment></React.Fragment>}
      title={allPages.find(p => p.path === location.pathname)?.title}
    />
  );
};

export default MainNavBar;
