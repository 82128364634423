import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import '@/i18n/config';
import reportWebVitals from './reportWebVitals';
import Router from './routes';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import '@arco-design/mobile-react/esm/style';

import { ContextProvider } from '@arco-design/mobile-react';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ContextProvider>
      <Router />
    </ContextProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

window.onload = function () {
  document.addEventListener('touchstart', function (event) {
    if (event.touches.length > 1) {
      event.preventDefault();
    }
  });

  document.addEventListener('gesturestart', function (event) {
    event.preventDefault();
  });
};
